import Sortable from "sortablejs";

initGeocode();
function initGeocode() {
  Array.from(document.getElementsByClassName("reverseGeocodeThis")).forEach((ele: HTMLElement) => {
    // @ts-ignore: Cannot find name 'axios'
    axios.get(`https://dev.virtualearth.net/REST/v1/Locations/${ele.dataset.latlng}?includeNeighborhood=true&key=AubIrtZtYNodyfhzr2F2qjzgrpBhDMH_gXDuMdmHhjUBsiUbCTUnf83GVb8ccjoa`)
      .then((res: any) => {
        ele.innerHTML += res.data.resourceSets[0].resources[0].address.addressLine.replace(/^\d+\s*/, "");
        ele.classList.add("geocodeDone");
      });
  });
}

Array.from(document.getElementsByClassName("ajaxSaver")).forEach((button: HTMLElement) => {
  button.addEventListener("click", (e: MouseEvent) => {
    const payload: any = {};
    if (button.dataset.source) {
      const formElement: any = document.getElementById(button.dataset.source);
      if (formElement.value) {
        payload[button.dataset.source] = formElement.value;
      }
    }
    if (button.dataset.radioSources) {
      const radioElements: NodeListOf<HTMLElement> = document.getElementsByName(button.dataset.radioSources);
      // @ts-ignore
      radioElements.forEach((ele: HTMLInputElement) => {
        if (ele.checked) {
          payload[button.dataset.radioSources] = ele.value;
        }
      });
    }
    fetch(button.dataset.target, {
      method: "post",
      mode: "cors",
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: new Headers({
        "IsAjax": "true",
        "Content-Type": "application/json",
      }),
    }).then((val) => {
      button.classList.add("saveSuccess");
      setTimeout(() => button.classList.remove("saveSuccess"), 3000);
    });
  });
});

const segmentArranger = document.getElementById("segmentArranger");
if (segmentArranger) {
  const sortableSegments = Sortable.create(segmentArranger, {
    onUpdate: (e: any) => {
      const optionId = e.to.dataset.optionId;
      const segmentElements = Array.from(e.to.childNodes);
      const segments = segmentElements.map((ele: any) => ele.dataset.segmentId);
      fetch(`/option/${optionId}/update`, {
        method: "post",
        mode: "cors",
        redirect: "follow",
        body: JSON.stringify({
          segments: segments,
        }),
        headers: new Headers({
          "IsAjax": "true",
          "Content-Type": "application/json",
        })
      }).then((val) => {
        console.log("Segment order updated");
      });
    }
  });
}

const requestForm = document.getElementById("createRequestForm");
if (requestForm) {
  requestForm.addEventListener("submit", (e) => {
    if (e.target instanceof HTMLFormElement) {
      const prevErrors = document.getElementsByClassName("error");
      for (let i = 0; i < prevErrors.length; i++) {
        prevErrors[i].classList.remove("error");
        i--;
      }
      let valid = true;
      const start: any = document.getElementById("startPointInput");
      const startMap: any = document.getElementById("startMap");
      const endMap: any = document.getElementById("endMap");
      const end: any = document.getElementById("endPointInput");
      const timeOfDayGroup: any = document.getElementById("timeOfDayGroup");
      const timeOfDayRadios = document.querySelectorAll("input[type=radio]:checked");
      const email: any = document.getElementById("email");
      if (start && startMap && !start.value) {
        valid = false;
        startMap.classList.add("error");
        startMap.dataset["error"] = "You didn't select a start point";
      }
      if (end && endMap && !end.value) {
        valid = false;
        endMap.classList.add("error");
        endMap.dataset["error"] = "You didn't select an end point";
      }
      if (email && !email.value) {
        valid = false;
        email.classList.add("error");
        email.dataset["error"] = "You didn't enter an email";
      }
      if (timeOfDayGroup && timeOfDayRadios && timeOfDayRadios.length === 0) {
        valid = false;
        timeOfDayGroup.classList.add("error");
        timeOfDayGroup.dataset["error"] = "You didn't select a time";
      }
      if (!valid) {
        e.preventDefault();
      }
    }
  }, true);
}

// @ts-ignore
const segmentAddForm = document.segmentAddForm;
if (segmentAddForm) {
  const typeRadios: any = segmentAddForm.routeType;
  Array.from(typeRadios).forEach((element: any) => {
    if (element.checked) {
      segmentAddForm.classList.remove("unknownMode");
      if (element.value === "Walking") {
        segmentAddForm.classList.add("walkingSegment");
      } else {
        segmentAddForm.classList.remove("walkingSegment");
      }
    }
    element.onclick = (ele: any) => {
      segmentAddForm.classList.remove("unknownMode");
      if (ele.target.value === "Walking") {
        segmentAddForm.classList.add("walkingSegment");
      } else {
        segmentAddForm.classList.remove("walkingSegment");
      }
    };
  });
}

const segmentInlineType = document.getElementById("segmentInlineType");
if (segmentInlineType) {
  segmentInlineType.onchange = (ev: any) => {
    // @ts-ignore
    document["inlineSegmentEditor"].classList = "";
    // @ts-ignore
    document["inlineSegmentEditor"].classList.add(ev.target.value.toLowerCase().replace(" ", ""));
    if (ev.target.value === "Walking") {
      Array.from(document.getElementsByClassName("hideWhenWalking")).forEach((ele: any) => {
        ele.getElementsByTagName("input")[0].value = "";
      });
    }
  };
}

Array.from(document.getElementsByClassName("reactionFormEle")).forEach((ele: any) => {
  ele.addEventListener("submit", (e: any) => {
    e.preventDefault();
    const body: any = {
      reactionType: ele.reactionType.value,
    };
    if (ele.reaction) {
      body["reaction"] = ele.reaction.value;
    }
    fetch(ele.action, {
      method: "post",
      mode: "cors",
      redirect: "follow",
      body: JSON.stringify(body),
      headers: new Headers({
        "IsAjax": "true",
        "Content-Type": "application/json",
      })
    }).then((val) => {
      ele.classList.add("done");
      setTimeout(() => ele.classList.remove("done"), 3000);
    });
  });
});